<template>
  <div id="app">


        <router-view></router-view>

    </div>

</template>

<script>
export default {
  name: 'app',
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

.hello {
  width: 100%;
}


@import "https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700&display=swap&subset=latin-ext";
@import "./assets/styles/css.css";
@import './assets/styles/bootstrap.css';
@import './assets/styles/swiper.css';
@import './assets/styles/keyboard.css';
@import './assets/styles/styles.css';
@import './assets/styles/magnific-popup.css';
@import './assets/styles/swiper.css';
@import './assets/font/fontawesome-all.css';
</style>
