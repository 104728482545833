import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta';

var VueCookie = require('vue-cookie');
Vue.use(VueCookie);
Vue.use(VueMeta);
  
Vue.config.productionTip = false
 
new Vue({
	router,
	render: h => h(App),
  }).$mount('#app')
