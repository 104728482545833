import Vue from "vue";
import Router from "vue-router";

  Vue.use(Router);
  
  export default new Router({
	mode: "history",
	routes: [
    {
		path: "/",
		alias: "/",
		name: "exercises",
		component: () => import("./components/Landing")
    },
    {
		path: "/exercise/:id",
		alias: "/exercise",
		name: "exercise",
		component: () => import("./components/HelloWorld")
    },
    {
		path: "/overview",
		alias: "/overview",
		name: "overview",
		component: () => import("./components/OverView")
    },
	{
		path: "/login",
		alias: "/login",
		name: "login",
		component: () => import("./components/Login")
	},
	]
  });
  